var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Log error messages if not in production env.
 *
 * @param {string} eventName - The name of the event.
 * @param {string} error - The error message.
 *
 * @returns {void}
 */
var logError = function (error) {
    var _a = window.nypScripts.isProduction, isProduction = _a === void 0 ? false : _a;
    if (isProduction) {
        return;
    }
    // eslint-disable-next-line no-console
    console.warn("Permutive error: ".concat(error));
};
/**
 * Converts an object into an array of objects with "key" and "value" properties.
 *
 * @param object - The object to be converted.
 *
 * @returns An array of objects with "key" and "value" properties.
 */
var convertObjectToArray = function (object) {
    if (!object) {
        return [];
    }
    var entries = Object.entries(object);
    var entriesMapped = entries.map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({ key: key, value: value });
    });
    return entriesMapped || [];
};
/**
 * Calculates the percentage progress based on progress and duration.
 *
 * @param {number} progress - The current progress.
 * @param {number} duration - The total duration.
 *
 * @returns {number} - The rounded-down percentage progress.
 */
var calcProgressPercentage = function (progress, duration) {
    var percentage = (progress / duration) * 100;
    var roundedPercentage = Math.floor(percentage);
    return roundedPercentage;
};
/**
 * An async function to fetch additional media data based on the media ID.
 *
 * @param {any} player - The player from which to get media ID.
 *
 * @returns {Promise<any | null>} - A Promise resolving to the data if success, else null.
 * @throws Will log and re-throw any errors encountered during data fetching.
 */
var getMedia = function (player) { return __awaiter(void 0, void 0, void 0, function () {
    var mediaId, ENDPOINT, HEADERS, res, data, e_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                mediaId = (_a = player === null || player === void 0 ? void 0 : player.getPlaylistItem()) === null || _a === void 0 ? void 0 : _a.mediaid;
                // Early return if no media ID.
                if (!mediaId) {
                    return [2 /*return*/, null];
                }
                ENDPOINT = "/wp-json/nyp-video-player/jwplayer/v1/media/".concat(mediaId);
                HEADERS = {
                    headers: {
                        'Content-Type': 'text/plain',
                        'X-Nypost-Rest-Auth': 'videoplayer-permutive',
                    },
                };
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(ENDPOINT, HEADERS)];
            case 2:
                res = _b.sent();
                return [4 /*yield*/, res.json()];
            case 3:
                data = _b.sent();
                if (!res.ok || data.errors) {
                    throw new Error("HTTP error: ".concat(res.status));
                }
                return [2 /*return*/, data];
            case 4:
                e_1 = _b.sent();
                logError(e_1);
                return [2 /*return*/, null];
            case 5: return [2 /*return*/];
        }
    });
}); };
export { logError, convertObjectToArray, calcProgressPercentage, getMedia, };
